import { Container, Row, Col } from 'react-bootstrap';
import { formatPrice } from 'schema/price.schema';
import { hasAdjudicatedPrice } from 'util/cart';
import { ReviewPrescriptionInformationProps } from '../review/types';
import { ExtendedRefillRxs } from 'types/order-prescription';
import { safeParseFloat } from 'util/number';

import BirdiPrice from 'components/birdi-price/birdi-price.component';
import AutoRefillBadge from 'components/new-medicine-cabinet-cart/prescription-information/auto-refill-badge/auto-refill-badge';
import { AutoRefillDisclaimer } from './auto-refill-disclaimer.component';

import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';

export const PrescriptionInformation: React.FC<ReviewPrescriptionInformationProps> = ({
    t,
    fullCart,
    prescriptions,
    accountHasInsurance,
    onPrescriptionAutoRefillChange
}) => {
    const cartItems = fullCart?.extendedRefillRxs;

    function formatBirdiSavings(item: ExtendedRefillRxs | undefined) {
        if (item) {
            const savingsAsDollar = safeParseFloat(item.awpPrice) - safeParseFloat(item.birdiPrice);
            return `${formatPrice(savingsAsDollar)}`;
        }
        return '';
    }

    return (
        <>
            <Container className="cart-prescription" fluid>
                {cartItems?.map((item) => {
                    const currentPrescription = prescriptions.find((obj) => {
                        return obj.rxNumber === item.rxNumber;
                    });

                    return (
                        <Row key={item.rxNumber} className="mt-4 pb-2 pb-md-4 cart-prescription--item">
                            <Col xs={7} sm={6} md={8} className="d-flex align-items-stretch flex-column">
                                <h3 className="cart-prescription--header">
                                    {currentPrescription?.dispensedProductName}
                                </h3>
                                <div className="cart-prescription--product-info">
                                    <div className="cart-prescription--product-info--rx-number">
                                        <b>Rx #{item.rxNumber}</b>
                                    </div>
                                    {currentPrescription?.fillQuantity && (
                                        <div className="cart-prescription--product-info--content">
                                            {t('pages.cart.quantity', {
                                                fillQuantity: currentPrescription?.fillQuantity
                                            })}
                                        </div>
                                    )}
                                    {(currentPrescription?.dispensedProductStrength ||
                                        currentPrescription?.dispensedProductUnitOfMeasure) && (
                                        <div className="cart-prescription--product-info--content">
                                            {currentPrescription?.dispensedProductStrength}
                                            {currentPrescription?.dispensedProductUnitOfMeasure}
                                        </div>
                                    )}
                                    {currentPrescription?.dispensedProductDosageForm && (
                                        <div className="cart-prescription--product-info--content">
                                            {currentPrescription?.dispensedProductDosageForm}
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col xs={5} sm={6} md={4} className="text-right text-lg-right">
                                <div className="d-flex flex-column">
                                    {!item.hasKnownPrice && (
                                        <div className="mb-3">{t('pages.cart.priceCurrentlyUnavailable')}</div>
                                    )}
                                    {(hasAdjudicatedPrice(item, currentPrescription) ||
                                        (!hasAdjudicatedPrice(item, currentPrescription) &&
                                            item.hasKnownPrice &&
                                            item.showBirdiPricing)) && (
                                        <>
                                            <div className="mb-3">
                                                <div>
                                                    <h3 className="cart-total--title">
                                                        <span>
                                                            {formatPrice(
                                                                item.isUsingBirdiPrice
                                                                    ? item.birdiPrice
                                                                    : item.patientCopay
                                                            )}
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="h6 cart-total--description mr-0 d-flex justify-content-end">
                                                        <BirdiPrice
                                                            accountHasInsurance={accountHasInsurance}
                                                            insuredText={t('pages.cart.rxItemTotal')}
                                                            showIfInsured={item.showBirdiPricing}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {!hasAdjudicatedPrice(item, currentPrescription) && item.hasKnownPrice && (
                                        <>
                                            {item.showStrikeThruPricing && (
                                                <>
                                                    <div className="mb-3">
                                                        <div>
                                                            <div className="cart-total--strikethrough">
                                                                <span className="text-decoration-line-through">
                                                                    {formatPrice(item.awpPrice)}
                                                                </span>
                                                            </div>
                                                            <h3 className="cart-total--title mb-0">
                                                                {formatPrice(item?.birdiPrice)}
                                                            </h3>
                                                            <div className="cart-total d-flex justify-content-end">
                                                                <BirdiPrice
                                                                    accountHasInsurance={accountHasInsurance}
                                                                    insuredText={t('pages.cart.rxItemTotal')}
                                                                    showIfInsured={true}
                                                                />
                                                            </div>
                                                            <div className="cart-total mt-2">
                                                                <span>
                                                                    {`${t('pages.cart.youSave')}: ${formatBirdiSavings(
                                                                        item
                                                                    )}`}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Col>
                            {currentPrescription?.autoRefillEnabled && (
                                <Col className="mt-3">
                                    <AutoRefillBadge
                                        text={t('components.medicineCabinetCart.prescriptionInfo.autoRefill')}
                                    />
                                </Col>
                            )}

                            {!currentPrescription?.autoRefillEnabled && currentPrescription?.autoRefillEligible && (
                                <Col className="mt-3">
                                    <AutoRefillDisclaimer
                                        t={t}
                                        prescription={currentPrescription}
                                        onAutoRefillChange={(prescription: PrescriptionObjectPayload, value: boolean) =>
                                            onPrescriptionAutoRefillChange(prescription, value)
                                        }
                                    ></AutoRefillDisclaimer>
                                </Col>
                            )}
                        </Row>
                    );
                })}
            </Container>
        </>
    );
};
