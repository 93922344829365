import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { accountHasInsuranceSelector, accountPlansSelector } from 'state/account/account.selectors';
import {
    cartOrderBillShipMethodSelector,
    cartOrderHeaderSelector,
    cartOrderPaymentCardSelector,
    cartOrderShippingAddressSelector,
    cartSelector
} from 'state/cart/cart.selectors';
import { ExtendedCartObjectPayload } from 'state/cart/cart.services';
import { drugSelector } from 'state/drug/drug.selectors';
import {
    medicineCabinetAutoRefillPlanEligibleSelector,
    medicineCabinetPrescriptionsSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { PrescriptionObjectPayload, ToggleAutoFillRequest } from 'state/medicine-cabinet/medicine-cabinet.services';
import { medicineCabinetToggleAutoRefillAllRxs } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';

import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_COST, processCart } from 'util/cart';
import { replaceStringWith } from 'util/string';

import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import PillBottleIcon from 'assets/icons/pillBottle.svg';
import PlaneIcon from 'assets/icons/plane.svg';
import InfoIcon from 'ui-kit/icons/info/circle-info-icon';

import { CartTotal } from 'components/cart/order-confirmation/cart-total.component';
import { PrescriptionInformation } from 'components/cart/order-confirmation/prescription-information.component';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';
import CtaBlock, { CtaBlockButtonProps } from 'components/cta-block/CtaBlock';
import { CartReviewSectionHeader } from 'components/cart-review-section-header';
import { AutoRefillTermsAndConditionsModal } from 'components/auto-refill-terms-and-conditions-modal';

import './index.style.scss';

const OrderConfirmation = ({ data }: { data: GatsbyTypes.CartOrderConfirmationDataQuery }) => {
    const { blueSkyBackground } = data;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // state
    const [currentShippingPrice, setCurrentShippingPrice] = useState<number>(0);
    const [hasUnknownPrice, setHasUnknownPrice] = useState<boolean>(false);
    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedCartObjectPayload>();
    const [initialOrderPrice, setInitialOrderPrice] = useState<string>('');

    // selectors
    const orderHeader = useSelector(cartOrderHeaderSelector);
    const shippingAddress = useSelector(cartOrderShippingAddressSelector);
    const paymentCard = useSelector(cartOrderPaymentCardSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const cartObject = useSelector(cartSelector);
    const prescriptionsObject = useSelector(medicineCabinetPrescriptionsSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const shipMethodId = useSelector(cartOrderBillShipMethodSelector);
    const displayShowToggleAutoRefillAll = useSelector(medicineCabinetAutoRefillPlanEligibleSelector);
    const { setPrescriptionFlowType } = useAddTransferPrescription();

    useEffect(() => {
        async function getCartOrder() {
            if (cartObject) {
                const extendedCart = processCart(
                    cartObject,
                    accountHasInsurance,
                    prescriptionsObject,
                    drugDiscountPrices,
                    accountPlans
                );
                setExtendedCartObject(extendedCart);
                setHasUnknownPrice(extendedCart.itemHasUnknownPrice);
                setInitialOrderPrice(extendedCart.orderTotal);
                setCurrentShippingPrice(extendedCart?.orderHeader?.orderHighPriority ? EXPEDITED_SHIPPING_COST : 0);
            }
        }
        getCartOrder();
    }, [cartObject, accountHasInsurance, prescriptionsObject, drugDiscountPrices, accountPlans]);

    const handleTransferPrescriptionClick = React.useCallback<NonNullable<CtaBlockButtonProps['onClick']>>(() => {
        setPrescriptionFlowType({ flowType: 'Transfer' });
        navigate('/secure/prescription');
    }, [setPrescriptionFlowType]);

    const handleAutoRefillToggle = useCallback(
        (rxNumbers: string[], autoRefillEnabled: boolean) => {
            const data: ToggleAutoFillRequest[] = rxNumbers.map((rx) => {
                return { RxNumber: rx, AutoFillToggle: autoRefillEnabled };
            });
            dispatch(
                medicineCabinetToggleAutoRefillAllRxs.trigger({
                    rxNumbers: data,
                    onSuccess: () => {
                        dispatch(closeModal({}));
                    },
                    onFailure: () => {}
                })
            );
        },
        [dispatch]
    );

    const handleAutoRefillInCard = useCallback(
        (prescription: PrescriptionObjectPayload, value: boolean) => {
            if (!prescription.autoRefillEnabled) {
                handleShowTCModal([prescription.rxNumber], value);
            } else {
                handleAutoRefillToggle([prescription.rxNumber], value);
            }
        },
        [handleAutoRefillToggle]
    );

    const handleShowTCModal = useCallback(
        (rxNumbers: string[], autoRefillEnabled: boolean) => {
            dispatch(
                openModal({
                    onClose: () => {
                        dispatch(closeModal({}));
                    },
                    ctas: [
                        {
                            label: t('modals.autoRefillTC.confirm'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(setBusyModal(true));
                                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
                            },
                            async: true,
                            dataGALocation: 'UnauthenticatedAutoRefillTCConfirm'
                        },
                        {
                            label: t('modals.autoRefillTC.cancel'),
                            variant: 'text',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'UnauthenticatedAutoRefillTCCancel',
                            className: 'p-4'
                        }
                    ],
                    bodyContent: <AutoRefillTermsAndConditionsModal t={t} />,
                    showClose: true
                })
            );
        },
        [dispatch, handleAutoRefillToggle, t]
    );

    return (
        <WorkflowLayout
            backgroundImage={blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('pages.cartOrderConfirmation.title') }}
            className="cart-order-confirmation"
        >
            <WorkflowLayoutFormWrapper
                title={t('pages.cartOrderConfirmation.headlineText')}
                eyebrowText={t('pages.cartOrderConfirmation.eyebrowText')}
                className="cart-order-confirmation-layout-form-wrapper"
                icon={<CartIcon className={'header-icon-container'} />}
            >
                <>
                    <Container fluid>
                        <Row className="cart-order-confirmation-order-message">
                            <Col xs={12} md={12} lg={12}>
                                <h4
                                    dangerouslySetInnerHTML={{
                                        __html: t('pages.cartOrderConfirmation.orderMessage', {
                                            orderNumber: orderHeader?.orderInvoiceNumber
                                        })
                                    }}
                                ></h4>
                            </Col>
                        </Row>
                    </Container>
                    <CartReviewSectionHeader
                        label={t('pages.cart.prescriptionInformation')}
                        pricingLabel={t('pages.cart.pricing')}
                    />

                    <PrescriptionInformation
                        accountHasInsurance={accountHasInsurance}
                        t={t}
                        fullCart={extendedCartObject}
                        prescriptions={prescriptionsObject}
                        onPrescriptionAutoRefillChange={handleAutoRefillInCard}
                    />
                    <CartTotal
                        t={t}
                        hasUnknownPrice={hasUnknownPrice}
                        initialOrderPrice={initialOrderPrice}
                        currentShippingPrice={currentShippingPrice}
                        accountHasInsurance={accountHasInsurance}
                    />
                    <CartReviewSectionHeader
                        label={t('pages.cartOrderConfirmation.sectionHeaders.shippingInformation')}
                    />
                    <Container fluid>
                        <Row>
                            <Col className="cart-order-confirmation-section-content p-0">
                                <div className="cart-order-confirmation-default-address-label">
                                    <h6>{t('pages.cartOrderConfirmation.shippingInformation.defaultAddressLabel')}</h6>
                                </div>
                                <div className="cart-order-confirmation-default-address">
                                    {shippingAddress && (
                                        <>
                                            <span className="cart-order-confirmation-section-sentence">
                                                {shippingAddress.address1}{' '}
                                                {shippingAddress.address2 ? shippingAddress.address2 : ''}
                                            </span>
                                            <span className="cart-order-confirmation-section-sentence">
                                                {shippingAddress.city}, {shippingAddress.state}{' '}
                                                {shippingAddress.zipcodeFour
                                                    ? `${shippingAddress.zipcode}-${shippingAddress.zipcodeFour}`
                                                    : shippingAddress.zipcode}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <CartReviewSectionHeader label={t('pages.cartOrderConfirmation.sectionHeaders.shippingMethod')} />
                    <Container fluid>
                        <Row>
                            <Col className="mt-2 mb-3">
                                <span>
                                    {shipMethodId === DEFAULT_SHIPPING_ID
                                        ? t('pages.cart.freeShipping')
                                        : t('pages.cart.expeditedShipping')}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="h6 cart-prescription--footer-info">
                                    {t('pages.cart.shippingFooter')}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <CartReviewSectionHeader
                        label={t('pages.cartOrderConfirmation.sectionHeaders.paymentInformation')}
                    />
                    <Container fluid>
                        <Row>
                            <Col className="cart-order-confirmation-section-content p-0">
                                <div className="cart-order-confirmation-default-payment-method-label">
                                    <h6>{t('pages.cartOrderConfirmation.paymentInformation.primaryPaymentMethod')}</h6>
                                </div>
                                <div className="cart-order-confirmation-default-payment-method">
                                    {paymentCard && (
                                        <>
                                            <span>
                                                {t('pages.cartOrderConfirmation.paymentInformation.cardEnding', {
                                                    cardType: paymentCard.cardType,
                                                    endingDigits: replaceStringWith(
                                                        paymentCard.secureCardNumber,
                                                        new RegExp(/\*/g),
                                                        ''
                                                    )
                                                })}
                                                <br />
                                                {paymentCard.cardName}
                                                <br />
                                                Exp. {paymentCard.cardExpiration}
                                            </span>
                                            <CreditCardIcon
                                                className="payment-card__credit-card-icon"
                                                variant={paymentCard.cardType}
                                            />
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {ENABLE_AUTO_REFILL && displayShowToggleAutoRefillAll && (
                        <Container fluid>
                            <Row>
                                <Col className="auto-refill-learn-more m-3 d-flex">
                                    <div className="auto-refill-learn-more-info-icon">
                                        <InfoIcon />
                                    </div>
                                    <div className="auto-refill-learn-more-text">
                                        <p className="text-dark text-left">
                                            {t('pages.cartOrderConfirmation.turnOnAutoRefill')}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    <CartReviewSectionHeader label={t('pages.prescriptionConfirmation.sectionHeaders.quickLinks')} />
                    <Container fluid>
                        <Row>
                            <Col xs={12} md={6} lg={4} className="p-1 cta-block-card">
                                <CtaBlock
                                    className="w-100 ml-2"
                                    iconContent={
                                        <img
                                            alt={t('pages.prescriptionConfirmation.cta.transferPrescription.imageAlt')}
                                            src={PlaneIcon}
                                        />
                                    }
                                    onClick={handleTransferPrescriptionClick}
                                    variant="button"
                                >
                                    {t('pages.prescriptionConfirmation.cta.transferPrescription.label')}
                                </CtaBlock>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="p-1 cta-block-card">
                                <CtaBlock
                                    className="w-100 text-decoration-underline"
                                    iconContent={
                                        <img
                                            alt={t('pages.prescriptionConfirmation.cta.medicineCabinet.label')}
                                            src={PillBottleIcon}
                                        />
                                    }
                                    to="/secure/medicine-cabinet"
                                >
                                    {t('pages.prescriptionConfirmation.cta.medicineCabinet.label')}
                                </CtaBlock>
                            </Col>
                        </Row>
                    </Container>
                </>
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default OrderConfirmation;

export const query = graphql`
    query CartOrderConfirmationData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
            nodes {
                field_physical_address
                field_mailing_address
            }
        }
    }
`;
