import { ReviewCartTotalProps } from './types';
import { Container, Row, Col } from 'react-bootstrap';
import { formatPrice } from 'schema/price.schema';

export const CartTotal = ({
    t,
    hasUnknownPrice,
    initialOrderPrice,
    currentShippingPrice,
    accountHasInsurance
}: ReviewCartTotalProps) => {
    return (
        <Container fluid className="cart-total d-flex flex-column mt-4 mb-4">
            <Row>
                <Col sm={12} lg={12}>
                    <div className="cart-total--subTotal d-flex justify-content-between">
                        <div className="h6 cart-total--title">
                            {t('pages.cart.subTotal')}
                            {hasUnknownPrice ? <sup>*</sup> : ''}:
                        </div>
                        <div className="h6">{formatPrice(initialOrderPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--shipping d-flex justify-content-between mt-3">
                        <div className="h6 cart-total--title">{t('pages.cart.shipping')}</div>
                        <div>{formatPrice(currentShippingPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--total d-flex justify-content-between mt-3 pt-4">
                        <div className="h6 cart-total--title">
                            {t('pages.cart.estTotal')}
                            {hasUnknownPrice ? <sup>*</sup> : ''}:
                        </div>
                        <div>{formatPrice(String(Number(initialOrderPrice) + Number(currentShippingPrice)))}</div>
                    </div>
                </Col>
            </Row>
            {hasUnknownPrice && accountHasInsurance && (
                <Row className="mt-4">
                    <Col className="cart-total--footer-info">{t('pages.cart.totalDisclaimerOrderConfirmation')}</Col>
                </Row>
            )}
        </Container>
    );
};
